import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import infoSlice, {infoReducerPostLogin, infoReducerPostGameList, infoReducerPostGameCategoryAndSupplier, infoReducerPostMyPoint, infoReducerPostEthAddr} from '../redux-store/slice/infoSlice';
import GameList from "../components/Main/GameList";
import Mypage from "../components/Main/Mypage";
import Login from "../components/Main/Login";
import Sample from "../components/Main/Sample";
import Image from 'next/image';
import Script from 'next/script';
import { useWindowSize } from "@react-hook/window-size/throttled";
import useScrollPosition from "@react-hook/window-scroll";
import { usePWAInstall } from 'react-use-pwa-install'
import { getCookie, setCookie, hasCookie } from 'cookies-next';

const Index = () => {
    const dispatch = useDispatch();
    const pwaInstaller = usePWAInstall();
    const count = useSelector((state) => state?.infoReducer?.value);
    const countTmp = useSelector((state) => state?.tmpReducer?.value);
    const isLogin = useSelector((state) => state?.infoReducer?.isLogin);
    const userInfo = useSelector((state) => state?.infoReducer?.userInfo);
    const isGameList = useSelector((state) => state?.infoReducer?.isGameList);
    const isLoading = useSelector((state) => state?.infoReducer?.isLoading);
    const gameList = useSelector((state) => state?.infoReducer?.gameList);
    const pageName = useSelector((state) => state?.infoReducer?.pageName);
    const [searchFormInputStr, setSearchFormInputStr] = useState('');
    const [isMobileMenuView, setIsMobileMenuView] = useState('off');
    const [screenWidth, screenHeight] = useWindowSize({ fps: 60 });
    const scrollY = useScrollPosition(60 /*frames per second*/);
    const [isVerticalScreen, setIsVerticalScreen] = useState(screenWidth < screenHeight);

    useEffect(() => {
        // console.log(count);
        // console.log(gameList);
        console.log(`reLoad pageName: ${pageName}`);
        // console.log(`reLoad isGameList: ${isGameList}`);
        // console.log(Object.keys(gameList).length);
        // dispatch(infoSlice.actions.increment());

        // dispatch(infoReducerPostLogin());
        dispatch(infoReducerPostGameCategoryAndSupplier());
        // eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

    if(isVerticalScreen && screenWidth > screenHeight) {
        setIsVerticalScreen(false);
        location.reload();
    } else if(!isVerticalScreen && screenWidth < screenHeight) {
        setIsVerticalScreen(true);
        location.reload();
    }

    function fcMobileMenu() {
        if(isMobileMenuView == 'on') {
            setIsMobileMenuView('off');
        } else {
            setIsMobileMenuView('on');
        }
    }
    
    function menuNameClickFc(menuNameStr, event) {
        dispatch(infoSlice.actions.setPageName(menuNameStr));
        setIsMobileMenuView('off');
        dispatch(infoReducerPostGameCategoryAndSupplier());
    
        event.preventDefault();
    }
    
    function searchClickFc(value, event) {
        dispatch(infoSlice.actions.setPageName('Search'));
        dispatch(infoReducerPostGameList({category: '', product: '', name: value}));
    
        event.preventDefault();
    }

    return (
        <>
            <div className="header-fixed header-mobile-fixed subheader-enabled subheader-fixed" cz-shortcut-listen="true" style={{backgroundColor: '#EEF0F8'}}>
                {
                    isLoading &&
                    // https://getbootstrap.com/docs/4.0/utilities/display/
                    <div className="page-loader bg-dark d-flex justify-content-center align-items-center" style={{opacity: '0.7'}}>
                        <div>
                            <span className="d-block spinner-primary spinner-center spinner-border text-primary text-block" role="status">&nbsp;&nbsp;&nbsp;Loading...</span>
                        </div>
                    </div>
                }

                <div id="kt_header_mobile" className="header-mobile align-items-center header-mobile-fixed" style={{backgroundColor: '#fff'}}>
                    <a href="" onClick={(event) => menuNameClickFc('Home', event)} className="pt-2">
                        <Image alt="Logo" src="images/bzon_logo.png" height={30} width={30} />
                    </a>
                    <div className="d-flex align-items-center">
                        <button className="btn p-0 burger-icon" id="kt_header_mobile_toggle" onClick={fcMobileMenu}>
                            <span></span>
                        </button>
                        {/* <button className="btn p-0 ml-2 pt-1 mr-2" id="kt_header_mobile_topbar_toggle">
                            <Image alt="User" src="demo1/dist/assets/media/svg/icons/General/User.svg" height={24} width={24} />
                        </button> */}
                    </div>
                </div>
                
                <div className="d-flex flex-column flex-root">
                    <div className="d-flex flex-row flex-column-fluid page">
                        <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">

                            <div id="kt_header" className="header header-fixed">
                                <div className="container-fluid d-flex align-items-stretch justify-content-between">
                                    <div className={`header-menu-wrapper header-menu-wrapper-left header-menu-wrapper-${isMobileMenuView}`} id="kt_header_menu_wrapper">
                                        <div className="header-logo">
                                            <a href="" onClick={(event) => menuNameClickFc('Home', event)} className="pt-2">
                                                <Image alt="Logo" src="images/bzon_logo.png" height={40} width={40} />
                                            </a>
                                        </div>

                                        <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                                            <ul className="menu-nav">
                                                <li className="menu-item menu-item-submenu menu-item-rel menu-item-close" data-menu-toggle="click" aria-required role="menugroup" aria-haspopup="true">
                                                    <a href="" className="menu-link menu-toggle" onClick={(event) => menuNameClickFc('Home', event)}>
                                                        <span className="menu-text">Home</span>
                                                        <i className="menu-arrow"></i>
                                                    </a>
                                                </li>
                                                <li className="menu-item menu-item-submenu menu-item-rel menu-item-close" data-menu-toggle="click" aria-required role="menugroup" aria-haspopup="true">
                                                    <a href="" className="menu-link menu-toggle" onClick={(event) => menuNameClickFc('Category', event)}>
                                                        <span className="menu-text">Category</span>
                                                        <i className="menu-arrow"></i>
                                                    </a>
                                                    <div className="menu-submenu menu-submenu-classic menu-submenu-left" data-hor-direction="menu-submenu-left">
                                                        <ul className="menu-subnav">
                                                            <li className="menu-item"  aria-required role="submenugroup" aria-haspopup="true">
                                                                <a href="index.html" className="menu-link" role="submenu">
                                                                    <span className="svg-icon menu-icon">
                                                                        ICON
                                                                    </span>
                                                                    <span className="menu-text">My Account</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li className="menu-item menu-item-submenu menu-item-rel menu-item-close" data-menu-toggle="click" aria-required role="menugroup" aria-haspopup="true">
                                                    <a href="" className="menu-link menu-toggle" onClick={(event) => menuNameClickFc('Supplier', event)}>
                                                        <span className="menu-text">Supplier</span>
                                                        <i className="menu-arrow"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        
                                        {/* <div id="kt_header_menu" className="header-menu header-menu-mobile header-menu-layout-default">
                                            <ul className="menu-nav">
                                                <li className="dropdown menu-item menu-item-submenu menu-item-rel menu-item-active menu-item-open-dropdown menu-item-hover">
                                                    <div className="topbar-item" data-toggle="dropdown" data-offset="105px,0px">
                                                        <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pl-10 pr-10">
                                                            <span className="menu-text" style={{fontSize: '1rem', fontWeight: '500', color: '#6c7293'}}>Category</span>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right mt-3">
                                                        <ul className="navi navi-hover py-4">
                                                            <li className="navi-item">
                                                                <a href="#" className="navi-link">
                                                                    <span className="symbol symbol-20 mr-3">
                                                                        <Image width={20} height={20} src="demo1/dist/assets/media/svg/flags/226-united-states.svg" alt="" />
                                                                    </span>
                                                                    <span className="navi-text">Baccart</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li className="dropdown menu-item menu-item-submenu menu-item-rel menu-item-active menu-item-open-dropdown menu-item-hover">
                                                    <div className="topbar-item" data-toggle="dropdown" data-offset="105px,0px">
                                                        <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pl-10 pr-10">
                                                            <span className="menu-text" style={{fontSize: '1rem', fontWeight: '500', color: '#6c7293'}}>Supplier</span>
                                                        </div>
                                                    </div>
                                                    <div className="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right mt-3">
                                                        <ul className="navi navi-hover py-4">
                                                            <li className="navi-item">
                                                                <a href="#" className="navi-link">
                                                                    <span className="symbol symbol-20 mr-3">
                                                                        <Image width={20} height={20} src="demo1/dist/assets/media/svg/flags/226-united-states.svg" alt="" />
                                                                    </span>
                                                                    <span className="navi-text">Asia Gaming</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    {
                                        isMobileMenuView == 'on' ? <div className="header-menu-wrapper-overlay" onClick={fcMobileMenu}></div> : null
                                    }
                                    
                                    <div className={`topbar ${screenWidth > 768 ? 'pc' : 'mobile'}`} style={screenWidth > 768 ? null : {top: '57px'}}>
                                        <div className="dropdown" id="kt_quick_search_toggle">
                                            <div className="topbar-item" data-toggle="dropdown" data-offset="10px,0px">
                                                <div className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
                                                    <span className="svg-icon svg-icon-xl svg-icon-primary">
                                                        <i className="flaticon-search text-primary"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
                                                <div className="quick-search quick-search-dropdown" id="kt_quick_search_dropdown">
                                                    <form method="get" className="quick-search-form">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <i className="flaticon-search"></i>
                                                                </span>
                                                            </div>
                                                            <input id="searchFormInput" type="text" className="form-control" placeholder="Game Title" value={searchFormInputStr} onChange={(event) => { setSearchFormInputStr(event.target.value)}} onKeyDown={(event) => {event.key  == 'Enter' && event.preventDefault()}} />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="quick-search-close ki ki-close icon-sm text-muted"></i>
                                                                </span>
                                                            </div>
                                                            <button type="button" className="btn btn-outline-primary" onClick={(event) => {searchClickFc(searchFormInputStr, event)}}>Search</button>
                                                        </div>
                                                    </form>
                                                    <div className="quick-search-wrapper scroll ps" data-scroll="true" data-height="325" data-mobile-height="200" style={{height: '325px', overflow: 'hidden'}}>
                                                        <div className="ps__rail-x" style={{left: '0px', bottom: '0px'}}>
                                                            <div className="ps__thumb-x" style={{left: '0px', width: '0px'}}>
                                                            </div>
                                                        </div>
                                                        <div className="ps__rail-y" style={{top: '0px', right: '0px'}}>
                                                            <div className="ps__thumb-y" style={{top: '0px', height: '0px'}}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropdown">
                                            <div className="topbar-item" data-toggle="dropdown" data-offset="10px,0px">
                                                <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
                                                    <Image width={20} height={20} className="h-20px w-20px rounded-sm" src="demo1/dist/assets/media/svg/flags/226-united-states.svg" alt="" />
                                                </div>
                                            </div>
                                            <div className="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right">
                                                <ul className="navi navi-hover py-4">
                                                    <li className="navi-item">
                                                        <a href="#" className="navi-link">
                                                            <span className="symbol symbol-20 mr-3">
                                                                <Image width={20} height={20} src="demo1/dist/assets/media/svg/flags/226-united-states.svg" alt="" />
                                                            </span>
                                                            <span className="navi-text">English</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="topbar-item" onClick={() => {
                                            if(isLogin && hasCookie('login_token')) {
                                                dispatch(infoReducerPostMyPoint());
                                                dispatch(infoReducerPostEthAddr());
                                            }
                                        }}>
                                            <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" data-toggle="modal" data-target={isLogin && hasCookie('login_token') ? '#divMypageModal' : '#divLoginModal'}>
                                                <span className="symbol symbol-lg-35 symbol-25 symbol-light-secondary">
                                                    <span className="symbol-label font-size-h5 font-weight-bold">
                                                        <i className="flaticon2-user"></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
                                    <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                        <div className="d-flex align-items-center flex-wrap mr-1">
                                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                                <h5 className="text-dark font-weight-bold my-1 mr-5">{pageName}</h5>
                                                <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
                                                    <li className="breadcrumb-item text-muted">
                                                        <a href="" className="text-muted"></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-column-fluid">
                                    <div className={`container`} style={screenWidth > 768 ? null : {marginTop: '20px'}}>
                                        <div className="row">
                                            <div className="col-lg-12 mb-5">
                                                {
                                                pwaInstaller && 
                                                    <button type="button" className="btn btn-danger col-lg-12" onClick={pwaInstaller}>
                                                        <span className="label pulse pulse-success mr-1">
                                                            <span className="position-relative flaticon-download-1"></span>
                                                            <span className="pulse-ring"></span>
                                                        </span>
                                                        <span>Installation of PWA type apps</span>
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 mb-5">
                                                <button type="button" className="btn btn-primary col-lg-12" onClick={(event) => searchClickFc('', event)}>All Games(4500+)</button>
                                            </div>
                                        </div>

                                        {
                                        isGameList ?
                                            pageName == 'Home' ? 
                                                Object.keys(gameList).map(function(value, key) {
                                                    return <GameList dataCategoryName={value} key={key} />
                                                })
                                            : pageName == 'Category' ? 
                                            <GameList />
                                            : pageName == 'Supplier' ? 
                                            <GameList />
                                            : pageName == 'Search' ? 
                                                gameList['Category List'].map(function(value, key) {
                                                    return <GameList dataCategoryName={value} key={key} />
                                                })
                                            :  
                                            null
                                        : 
                                        <div className="spinner spinner-primary spinner-center mb-10"></div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
                                <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                                    <div className="text-dark order-2 order-md-1">
                                        <span className="text-muted font-weight-bold mr-2">2023©</span>
                                        <a href="" target="_blank" className="text-dark-75 text-hover-primary" rel="noreferrer" onClick={(event) => menuNameClickFc('Home', event)}>BZON</a>
                                    </div>
                                    <div className="nav nav-dark">
                                        <a href="" target="_blank" className="nav-link pl-0 pr-5" rel="noreferrer" onClick={(event) => menuNameClickFc('Home', event)}>About</a>
                                        <a href="" target="_blank" className="nav-link pl-0 pr-5" rel="noreferrer" onClick={(event) => menuNameClickFc('Home', event)}>Team</a>
                                        <a href="" target="_blank" className="nav-link pl-0 pr-0" rel="noreferrer" onClick={(event) => menuNameClickFc('Home', event)}>Contact</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="divMypageModal" data-backdrop="static" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <Mypage />
                </div>

                <div className="modal fade" id="divLoginModal" data-backdrop="static" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
                    <Login />
                </div>
            </div>

            <Script id="my-script">{`
              console.log('Not Include Webpack - Hello world!');
              // for Metronic Script & Jquery
              let HOST_URL = "https://preview.keenthemes.com/metronic/theme/html/tools/preview"; 
              let KTAppSettings = { "breakpoints": { "sm": 576, "md": 768, "lg": 992, "xl": 1200, "xxl": 1400 }, "colors": { "theme": { "base": { "white": "#ffffff", "primary": "#3699FF", "secondary": "#E5EAEE", "success": "#1BC5BD", "info": "#8950FC", "warning": "#FFA800", "danger": "#F64E60", "light": "#E4E6EF", "dark": "#181C32" }, "light": { "white": "#ffffff", "primary": "#E1F0FF", "secondary": "#EBEDF3", "success": "#C9F7F5", "info": "#EEE5FF", "warning": "#FFF4DE", "danger": "#FFE2E5", "light": "#F3F6F9", "dark": "#D6D6E0" }, "inverse": { "white": "#ffffff", "primary": "#ffffff", "secondary": "#3F4254", "success": "#ffffff", "info": "#ffffff", "warning": "#ffffff", "danger": "#ffffff", "light": "#464E5F", "dark": "#ffffff" } }, "gray": { "gray-100": "#F3F6F9", "gray-200": "#EBEDF3", "gray-300": "#E4E6EF", "gray-400": "#D1D3E0", "gray-500": "#B5B5C3", "gray-600": "#7E8299", "gray-700": "#5E6278", "gray-800": "#3F4254", "gray-900": "#181C32" } }, "font-family": "Poppins" };
              let objInterval = setInterval(function() {
                if (typeof jQuery !== 'undefined' && typeof jQuery.fn.jquery !== 'undefined') {
                  $(document).ready(function() {
                    console.log('jQuery Document Ready!!' + $().jquery);
                    // $.getScript("/demo1/dist/assets/plugins/custom/prismjs/prismjs.bundle.js", function() {
                    //     console.log('Script Loaded');
                    // });
                    // $.getScript("/demo1/dist/assets/js/scripts.bundle.js", function() {
                    //     console.log('Script Loaded');
                    // });
                    // $('body').append('<script src="/demo1/dist/assets/plugins/custom/fullcalendar/fullcalendar.bundle.js"></script>');
                    // $('body').append('<script src="/demo1/dist/assets/js/pages/widgets.js"></script>');
                    
                    clearInterval(objInterval); 
                  });
                }
              }, 500);
              /// for Metronic Script & Jquery
            `}</Script>
            
        </>
    );
};

export default Index;
