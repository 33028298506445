import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import infoSlice, { extraRPostGameList, infoReducerPostGameList } from '../../redux-store/slice/infoSlice';

import Game from "./Atom/Game";

const GameList = ({dataCategoryName}) => {
    const dispatch = useDispatch();

    const isGameList = useSelector((state) => state?.infoReducer?.isGameList);
    const gameList = useSelector((state) => state?.infoReducer?.gameList);
    const pageName = useSelector((state) => state?.infoReducer?.pageName);

    function searchClickFc(value, kind, event) {
        dispatch(infoSlice.actions.setPageName('Search'));
        kind == 'Category' && dispatch(infoReducerPostGameList({category: value, product: '', name: ''}));
        kind == 'Supplier' && dispatch(infoReducerPostGameList({category: '', product: value, name: ''}));

        event.preventDefault();
    }

    return (
        <>
        {
        pageName == 'Home' ?
            dataCategoryName == 'Product List' | dataCategoryName == 'Category List' ?
            null
            :
            <div className="row">
                <div className="col-lg-12">
                    <div className="card card-custom card-stretch gutter-b">
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title font-weight-bolder">{dataCategoryName}</h3>
                        </div>
                        <div className="card-body d-flex flex-column">
                            <div className="row m-0">
                                {
                                isGameList
                                ?
                                    pageName == 'Home' ? 
                                        Object.keys(gameList[dataCategoryName]).map(function(value, key) {
                                            return <Game {...gameList[dataCategoryName][value]} key={key} />
                                        })
                                        : pageName == 'Category' ?
                                        null
                                        : null
                                : 'loading...'
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        : pageName == 'Category' ?
        <div className="row">
            {
            gameList['Category List'].map(function(value, key) {
                return  <div className="col-lg-3" key={key} onClick={(event) => searchClickFc(value, 'Category', event)} role='button'>
                            <div className="card card-custom card-stretch gutter-b">
                                <div className="card-body d-flex flex-column" style={{backgroundImage: `url(/images/category/${value.replace(/\s/g,'_').replace(/'/g,'').toLowerCase()}.png)`, height: `150px`, backgroundSize: `cover`, backgroundPosition: `center`}}>
                                    <div className="row m-0">
                                    </div>
                                </div>
                                <div class="card-footer d-flex justify-content-between">
                                    <a href="" className="text-warning font-weight-bold font-size-h6">{key+1}. {value}</a>
                                </div>
                            </div>
                        </div>
            })
            }
        </div>
        : pageName == 'Supplier' ?
        <div className="row">
            {
            gameList['Product List'].map(function(value, key) {
                return  <div className="col-lg-3" key={key} onClick={(event) => searchClickFc(value, 'Supplier', event)} role='button'>
                            <div className="card card-custom card-stretch gutter-b">
                                <div className="card-body d-flex flex-column" style={{backgroundImage: `url(/images/supplier/${value.replace(/\s/g,'_').replace(/'/g,'').toLowerCase()}.png)`, height: `150px`, backgroundSize: `cover`, backgroundPosition: `center`}}>
                                    <div className="row m-0">
                                    </div>
                                </div>
                                <div class="card-footer d-flex justify-content-between">
                                    <a href="" className="text-warning font-weight-bold font-size-h6">{key+1}. {value}</a>
                                </div>
                            </div>
                        </div>
            })
            }
        </div>
        : pageName == 'Search' ?
        <div className="row">
            <div className="col-lg-12">
                <div className="card card-custom card-stretch gutter-b">
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title font-weight-bolder">{dataCategoryName}</h3>
                    </div>
                    <div className="card-body d-flex flex-column">
                        <div className="row m-0">
                            {
                            isGameList
                            ?
                                pageName == 'Search' ? 
                                    gameList['Game List Category'] != undefined &&
                                    Object.keys(gameList['Game List Category'][dataCategoryName]).map(function(value, key) {
                                        return <Game {...gameList['Game List Category'][dataCategoryName][value]} key={key} />
                                    })
                                    : pageName == 'Category' ?
                                    null
                                    : null
                            : 'loading...'
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :
        null
        }
        </>
    );
};

export default GameList;
