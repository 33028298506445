import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import infoSlice, {infoReducerPostMyPoint, infoReducerPostEthAddr, infoReducerPostBettingHistory, infoReducerPostFullTransaction, infoReducerPostReferralNetwork} from '../../redux-store/slice/infoSlice';
import Moment from 'moment/moment';
import axios from 'axios';
import { getCookie, setCookie, hasCookie, removeCookies } from 'cookies-next';

const Mypage = ({dataCategoryName}) => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state?.infoReducer?.userInfo);
    const myPoint = useSelector((state) => state?.infoReducer?.myPoint);
    const myEthAddr = useSelector((state) => state?.infoReducer?.myEthAddr);
    const myBettingHistory = useSelector((state) => state?.infoReducer?.myBettingHistory);
    const myFullTransaction = useSelector((state) => state?.infoReducer?.myFullTransaction);
    const myReferralNetwork = useSelector((state) => state?.infoReducer?.myReferralNetwork);

    const [selectedValue, setSelectedValue] = useState('USDT');
    const [selectedAddress, setSelectedAddress] = useState('0xdac17f958d2ee523a2206206994597c13d831ec7');

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [reNewPassword, setReNewPassword] = useState('');


    const handleSelectChange = (event) => {
        const selectedOption = event.target.selectedOptions[0];
        setSelectedValue(selectedOption.value);
        setSelectedAddress(selectedOption.dataset.contractAddress);
    };

    const handleCurrentPasswordChange = (event) => {
        setCurrentPassword(event.target.value);
    }

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    }

    const handleReNewPasswordChange = (event) => {
        setReNewPassword(event.target.value);
    }
    
    const selectErc20ToeknRef = useRef(null);
    const inputErc20Address = useRef(null);
    const inputErc20Amount = useRef(null);

    useEffect(() => {
        // dispatch(infoReducerPostMyPoint);
        // dispatch(infoReducerPostEthAddr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    function tmpClickFn(value, event) {
        event.preventDefault();
    }

    function coinAddressCopyOnClickFc() {
        navigator.clipboard.writeText(myEthAddr?.result && myEthAddr?.result_data?.eth_addr); 
        toastr.info(`Copied the text: ${myEthAddr?.result && myEthAddr?.result_data?.eth_addr}`);
    }

    async function erc20TokenSendClickFc(value, event) {
        event.preventDefault();
        document.querySelector('#aErc20TokenSend').style.display = 'none';
        const selectErc20ToeknRefValue = selectErc20ToeknRef.current.value;
        const selectErc20ToeknRefContractValue = selectErc20ToeknRef.current.options[selectErc20ToeknRef.current.selectedIndex].dataset.contractAddress;
        const inputErc20AddressValue = inputErc20Address.current.value;
        const inputErc20AmountValue = inputErc20Amount.current.value;

        toastr.info(`Success Send`);
        return true;

        try {
            const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/point/exchange_erc20_usdt`, 
            {   
                lang: 'en',
                eth_addr: inputErc20AddressValue, 
                point_qty: inputErc20AmountValue,
            },
            {
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
            });
    
            // console.dir(response.data, {depth: Infinity})
            if(response.data.result) {
                toastr.info(`Success Send`);
            } else {
                toastr.error(response.data.result_msg);
            }
            document.querySelector('#aErc20TokenSend').style.display = 'inline-block';
        } catch (error) {
            toastr.error(`AXIOS Error: withdraw erc20`);
            document.querySelector('#aErc20TokenSend').style.display = 'inline-block';
        }
    }

    async function passwordModifyonClickFc(value, event) {
        event.preventDefault();

        if(newPassword !== reNewPassword) {
            toastr.error('newPassword and reNewPassword are not equal');
            return false;
        }

        try {
            const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/member/chg_user_pw_2`, 
            {   
                lang: 'en',
                user_pw: currentPassword, 
                new_user_pw: newPassword,
            },
            {
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
            });
    
            // console.dir(response.data, {depth: Infinity})
            if(response.data.result) {
                toastr.info(`Success Send`);
            } else {
                toastr.error(response.data.result_msg);
            }
        } catch (error) {
            toastr.error(`AXIOS Error: modify password`);
        }
    }

    function logoutClickFn() {
        window.localStorage.clear();
        removeCookies('user_id');
        removeCookies('login_token');
        location.href = '/'
    }

    return (
        <>
        <div className="modal-dialog modal-dialog-top modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    {
                        myPoint?.result == true ?
                        <h5 className="modal-title" id="exampleModalLabel">My Page(My Point: {myPoint?.result_data[0]?.point_qty === undefined ? 0 : myPoint?.result_data[0]?.point_qty})</h5>
                        :
                        <h5 className="modal-title" id="exampleModalLabel">My Page(My Point: 0)</h5>
                    }
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="d-flex justify-content-end">
                        <a href="#" className="btn btn-light-primary font-weight-bold mb-5" onClick={event => logoutClickFn('',event)}>Logout</a>
                    </div>
                    
                    <div className="card card-custom">
                        <div className="card-header">
                            <div className="card-toolbar">
                                <ul className="nav nav-light-success nav-bold nav-pills" style={{justifyContent: 'space-evenly'}}>
                                    <li className="nav-item">
                                        <a className="nav-link active" data-toggle="tab" href="#kt_tab_pane_4_1">
                                            <span className="nav-icon"><i className="flaticon2-chat-1"></i></span>
                                            <span className="nav-text" onClick={event => tmpClickFn('',event)}>Deposit</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#kt_tab_pane_4_2">
                                            <span className="nav-icon"><i className="flaticon2-drop"></i></span>
                                            <span className="nav-text">Withdraw</span>
                                        </a>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                            <span className="nav-icon"><i className="flaticon2-gear"></i></span>
                                            <span className="nav-text">History</span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                            <a className="dropdown-item" data-toggle="tab" href="#kt_tab_pane_4_3" onClick={(e) => {e.preventDefault; dispatch(infoReducerPostBettingHistory({beginDate:Moment().add(-1, `months`).format('YYYY-MM-DD'), endDate:Moment().format('YYYY-MM-DD')}))}}>Betting</a>
                                            <a className="dropdown-item" data-toggle="tab" href="#kt_tab_pane_4_4" onClick={(e) => {e.preventDefault; dispatch(infoReducerPostFullTransaction({operatorName:getCookie('agent_id'), user:userInfo?.result_data?.user_id, beginDate:Moment().add(-1, `months`).format('YYYY-MM-DD'), endDate:Moment().format('YYYY-MM-DD')}))}}>Full Transaction</a>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                            <span className="nav-icon"><i className="flaticon2-gear"></i></span>
                                            <span className="nav-text">My Profile</span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                            <a className="dropdown-item" data-toggle="tab" href="#kt_tab_pane_5_1">Agent Code</a>
                                            {/* <a className="dropdown-item" data-toggle="tab" href="#kt_tab_pane_5_2" onClick={(e) => {e.preventDefault; dispatch(infoReducerPostReferralNetwork())}}>Referral Network</a> */}
                                            <a className="dropdown-item" data-toggle="tab" href="#kt_tab_pane_5_3">Modify password</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="kt_tab_pane_4_1" role="tabpanel" aria-labelledby="kt_tab_pane_4_1">
                                    <div className="form-group">
                                        <div className="alert alert-custom alert-default" role="alert">
                                            <div className="alert-icon"><i className="flaticon-questions-circular-button"></i></div>
                                            <div className="alert-text">
                                                Sending to my token address will automatically recharge My Points.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="selectTokenKind">Select ERC20 token</label>
                                        <select className="form-control" value={selectedValue} onChange={handleSelectChange}>
                                            <option value="USDT" data-contract-address="0xdac17f958d2ee523a2206206994597c13d831ec7" selected>USDT</option>
                                            {/* <option value="USDT2" data-contract-address="0xdac17f958d2ee523a2206206994597c13d831ec72" >USDT2</option> */}
                                        </select>
                                        {/* <p>선택된 value 값: {selectedValue}</p>
                                        <p>선택된 data-contract-address 값: {selectedAddress}</p> */}
                                    </div>
                                    <div className="form-group">
                                        <label>My token address</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"><span className="input-group-text">ERC20</span></div>
                                            <input id="inputUserCoinAddress" type="text" className="form-control" placeholder={myEthAddr?.result && myEthAddr?.result_data?.eth_addr} disabled />
                                            <div class="input-group-append" onClick={coinAddressCopyOnClickFc}>
                                                <span class="input-group-text line-height-0 py-0">
                                                    Copy Address
                                                </span>
                                            </div>
                                        </div>
                                        <span className="form-text text-muted">The exchange rate is 1 : <span id="spanCoinRatio" style={{color: '#f00'}}>1</span> POINT</span>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="kt_tab_pane_4_2" role="tabpanel" aria-labelledby="kt_tab_pane_4_2">
                                    <div className="form-group">
                                        <div className="alert alert-custom alert-default" role="alert">
                                            <div className="alert-icon"><i className="flaticon-questions-circular-button"></i></div>
                                            <div className="alert-text">
                                                Please enter a valid receiving address.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label for="selectTokenKind">Select ERC20 token  
                                            <span className="text-danger">*</span>
                                        </label>
                                        <select className="form-control" id="selectTokenKind" ref={selectErc20ToeknRef}>
                                            <option value="USDT" data-contract-address="0xdac17f958d2ee523a2206206994597c13d831ec7" selected>USDT</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>My token address</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"><span className="input-group-text">ERC20</span></div>
                                            <input id="inputUserCoinAddress" type="text" className="form-control" placeholder="" ref={inputErc20Address} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Withdraw point amount</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"><span className="input-group-text">Point</span></div>
                                            <input id="inputUserExchangePoint" type="text" className="form-control" placeholder="" ref={inputErc20Amount} />
                                        </div>
                                        <span className="form-text text-muted">The ratio is 1 Coin : <span id="spanCoinRatio" style={{color: '#f00'}}>1</span> POINT</span>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <a id="aErc20TokenSend" href="#" className="btn btn-light-primary font-weight-bold" onClick={event => erc20TokenSendClickFc('',event)}>Apply</a>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="kt_tab_pane_4_3" role="tabpanel" aria-labelledby="kt_tab_pane_4_3">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Order ID</th>
                                                    <th scope="col">Insert Date</th>
                                                    <th scope="col">Game Code</th>
                                                    <th scope="col">Bet Amount</th>
                                                    <th scope="col">Process Type</th>
                                                    <th scope="col">Process Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {myBettingHistory?.result && myBettingHistory?.result_data.map((value, key) => { return (
                                                    <tr key={key}>
                                                        <td>{value.seq}</td>
                                                        <td>{value.insert_dt}</td>
                                                        <td>{value.game_code}</td>
                                                        <td>{value.point_qty}</td>
                                                        <td>{value.proc_type}</td>
                                                        <td>{value.proc_status}</td>
                                                    </tr>
                                                )})}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="kt_tab_pane_4_4" role="tabpanel" aria-labelledby="kt_tab_pane_4_4">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Order ID</th>
                                                    <th scope="col">Insert Date</th>
                                                    <th scope="col">Game Code</th>
                                                    <th scope="col">Bet Amount</th>
                                                    <th scope="col">Process Type</th>
                                                    <th scope="col">Process Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {myFullTransaction?.result && myFullTransaction?.result_data?.list.map((value, key) => { return (
                                                    <tr key={key}>
                                                        <td>{value.seq}</td>
                                                        <td>{value.insert_dt}</td>
                                                        <td>{value.game_code}</td>
                                                        <td>{value.point_qty}</td>
                                                        <td>{value.proc_type}</td>
                                                        <td>{value.proc_status}</td>
                                                    </tr>
                                                )})}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="kt_tab_pane_5_1" role="tabpanel" aria-labelledby="kt_tab_pane_5_1">
                                    <div className="form-group">
                                        <label>Agent Code</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"><span className="input-group-text">Code</span></div>
                                            <input type="text" className="form-control" placeholder="" disabled value={userInfo?.result && userInfo?.result_data?.agent_id} />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="kt_tab_pane_5_2" role="tabpanel" aria-labelledby="kt_tab_pane_5_2">
                                    <div className="form-group">
                                        <label>Referral Network</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"><span className="input-group-text">ID</span></div>
                                            <input type="text" className="form-control" placeholder="" disabled value={myReferralNetwork?.result && myReferralNetwork?.result_data?.recommender} />
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="kt_tab_pane_5_3" role="tabpanel" aria-labelledby="kt_tab_pane_5_3">
                                    <div className="form-group">
                                        <label>Current Password</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="" value={currentPassword} onChange={handleCurrentPasswordChange} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>New Password</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="" value={newPassword} onChange={handleNewPasswordChange} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Re New Password</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="" value={reNewPassword} onChange={handleReNewPasswordChange} />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <a href="#" className="btn btn-light-primary font-weight-bold" onClick={event => passwordModifyonClickFc('',event)}>Apply</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Mypage;
