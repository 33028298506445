import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import infoSlice, {infoReducerPostGameUrl} from '../../../redux-store/slice/infoSlice';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

const Game = ({name, product, url_thumb, game_id, game_code}) => {
    const dispatch = useDispatch();

    const isLogin = useSelector((state) => state?.infoReducer?.isLogin);

    function gameClickFc({game_id, game_code}) {
        if(isLogin) {
            dispatch(infoReducerPostGameUrl({platform: isMobile? 'GPL_MOBILE': 'GPL_DESKTOP', game_id, game_code})).then(_res => {
                if(_res.payload.result === true) {
                    isMobile ? location.href = _res.payload.url : window.open(_res.payload.url);
                } else {
                    toast.error(_res.payload.result_msg);
                }
                
            });
        } else {
            toast.error('Login is required');
        }
        
    }

    return (
        <>
            <div className="col-lg-2 col-md-6 col-sm-1 w-165px mb-7" onClick={(event) => gameClickFc({game_id: game_id, game_code: game_code}, event)} role="button">
                <div className="rounded-xl" style={{backgroundImage: `url(${url_thumb})`, height: `150px`, backgroundSize: `cover`, backgroundPosition: `center`}} />
                <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    {name}
                </span>
                <a href="#" className="text-warning font-weight-bold font-size-h6">{product}</a>
            </div>
        </>
    );
};

export default Game;
