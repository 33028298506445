import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import infoSlice from '../../redux-store/slice/infoSlice';

const Sample = ({dataCategoryName}) => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state?.infoReducer?.userInfo);
    const [myPoint, setMyPoint] = useState(0);

    useEffect(() => {
        
    },[]);

    function tmpClickFn(value, event) {
        event.preventDefault();
        setMyPoint(myPoint++);
    }

    return (
        <>
            <button onClick={event => tmpClickFn('',event)}>Go {myPoint}</button>
        </>
    );
};

export default Sample;
