import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import infoSlice, {infoReducerPostLogin} from '../../redux-store/slice/infoSlice';
import { getCookie, setCookie, hasCookie } from 'cookies-next';
import axios from "axios";

const Login = ({dataCategoryName}) => {
    const dispatch = useDispatch();

    const inputAgentId = useRef(null);
    const inputEmail = useRef(null);
    const inputPassword = useRef(null);
    
    const inputAgentIdForSignup = useRef(null);
    const inputEmailForSignup = useRef(null);
    const inputPasswordForSignup = useRef(null);
    const inputRePasswordForSignup = useRef(null);

    const [signUpBoolen, setSignUpBoolen] = useState(false);

    useEffect(() => {
        
    },[]);

    function signUpShowClickFn(value, event) {
        event.preventDefault();
        setSignUpBoolen(true);
    }

    function loginClickFn(value, event) {
        event.preventDefault();
        dispatch(infoReducerPostLogin({agentId:inputAgentId.current.value, userId:inputEmail.current.value, userPwd:inputPassword.current.value}))
    }

    function signUpCloseClickFn(value, event) {
        event.preventDefault();
        setSignUpBoolen(false);
    }
    
    async function signUpClickFn(value, event) {
        event.preventDefault();
        
        if(inputPasswordForSignup.current.value != inputRePasswordForSignup.current.value) {
            toastr.error('Please check Password and Re Password again');
            return false;
        }

        try {
            const response = await axios.post(process.env.NEXT_PUBLIC_PROXY_API_URL + `/member/join`, 
            {   
                lang: 'en',
                agent_id: inputAgentIdForSignup.current.value, 
                user_id: inputEmailForSignup.current.value,
                user_pw: inputPasswordForSignup.current.value,
                email: inputEmailForSignup.current.value,
            },
            {
                headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'},
            });
    
            // console.dir(response.data, {depth: Infinity})
            if(response.data.result) {
                toastr.info(`Success Send`);
                setSignUpBoolen(false);
            } else {
                toastr.error(response.data.result_msg);
            }
        } catch (error) {
            toastr.error(`AXIOS Error: modify password`);
        }
    }

    return (
        <>
        {signUpBoolen ?
        <div className="modal-dialog modal-dialog-top modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Sign Up</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {setSignUpBoolen(false)}}>
                        <i aria-hidden="true" className="ki ki-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label>Agent ID</label>
                        <div className="input-group">
                            <input type="text" className="form-control" value={getCookie('agent_id')} ref={inputAgentIdForSignup} readonly="true" style={{backgroundColor:'#b3afaf'}} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="" ref={inputEmailForSignup} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <div className="input-group">
                            <input type="password" className="form-control" placeholder="" ref={inputPasswordForSignup} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Re Password</label>
                        <div className="input-group">
                            <input type="password" className="form-control" placeholder="" ref={inputRePasswordForSignup} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <a href="#" className="btn btn-light-warning font-weight-bold" onClick={event => signUpCloseClickFn('', event)}>Close</a>
                        <a href="#" className="btn btn-light-primary font-weight-bold" onClick={event => signUpClickFn('', event)}>Sign Up</a>
                    </div>
                </div>
            </div>
        </div>
        :
        <div className="modal-dialog modal-dialog-top modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Login</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group">
                        <label>Agent ID</label>
                        <div className="input-group">
                            <input type="text" className="form-control" value={getCookie('agent_id')} ref={inputAgentId} readonly="true" style={{backgroundColor:'#b3afaf'}} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="" ref={inputEmail} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <div className="input-group">
                            <input type="password" className="form-control" placeholder="" ref={inputPassword} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <a href="#" className="btn btn-light-warning font-weight-bold" onClick={event => signUpShowClickFn('', event)}>Sign Up</a>
                        <a href="#" className="btn btn-light-primary font-weight-bold" onClick={event => loginClickFn('', event)}>Login</a>
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    );
};

export default Login;
